"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function polyfill() {
    var _a, _b;
    try {
        if ((navigator === null || navigator === void 0 ? void 0 : navigator.product) === 'ReactNative' && FormData && !((_a = FormData === null || FormData === void 0 ? void 0 : FormData.prototype) === null || _a === void 0 ? void 0 : _a.getAll)) {
            FormData.prototype.getAll = FormData.prototype.getParts;
        }
    }
    catch (err) {
        // eslint-disable-next-line no-console
        ((_b = process === null || process === void 0 ? void 0 : process.env) === null || _b === void 0 ? void 0 : _b.NODE_ENV) !== 'production' && console.error(err);
    }
}
exports.default = polyfill;
